<template>
  <en-dialog :visible="visible"
    title="充值"
    en-body-scroll
    width="600px"
    @close="visible = false">
      <el-form :model="formData" ref="form" label-width="70px" class="amount-form" @submit.native.prevent>
        <el-form-item
          label="充值金额"
          prop="amount"
          :rules="[
            { required: true, message: '金额不能为空'}
          ]"
        >
          <en-input-number
            v-model.number="formData.amount"
            style="width: 100%;"
            :precision="2"
            :max="9999999.99"
            @keydown.prevent
            @keyup.enter.native.prevent="handleConfirm"
            :min="0.1"
          >
            <template slot="suffix">
              元
            </template>
          </en-input-number>
        </el-form-item>
      </el-form>
     <span slot="footer" class="dialog-footer">
      <en-button type="primary" @click="handleConfirm">确 定</en-button>
    </span>
  </en-dialog>
</template>

<script>
import { mapState } from "vuex";
import { rechargeService } from "@/api";

export default {
  data() {
    return {
      visible: false,
      formData: {
        amount: ""
      }
    };
  },
  computed: {
    ...mapState(["userInfo"])
  },
  methods: {
    show() {
      this.visible = true;
    },
    handleConfirm() {
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          const res = await rechargeService.onlineRechargePlaceOrder({
            userId: this.userInfo.userId,
            amount: this.formData.amount,
            type: 2
          });
           const route = {
            path: "/sysPayment",
            query: {
              orderId: res.orderId
            }
          };
          const { href } = this.$router.resolve(route);
          window.location.href = href;
          // window.open(zjRouter.href, "_black");
          this.visible = false;
        }
      });
    },
    async queryUserOnlineRechargeList() {
      const { pageNo, pageSize } = this.rechargeData;
      const params = {
        pageNo,
        pageSize,
        userId: this.userInfo.userId
      };
      this.loading = true;
      const res = await rechargeService.queryUserOnlineRechargeList(params);
      this.loading = false;
      this.rechargeData = res;
    }
  }
};
</script>

<style lang="scss">
.el-dialog {
  .field-statistics {
    font-size: 12px;
    .value {
      font-weight: bold;
    }
  }
  .amount-form {
    padding: 10px 0 100px 0;
  }
}
</style>
