<template>
  <en-dialog :visible="visible"
    title="充值记录"
    en-body-scroll
    width="1100px"
    @close="visible = false">
    <en-table
      class="table"
      ref="enTable"
      height="500"
      :loading="loading"
      :data="rechargeData.records"
      :page-config="{ pageModel: rechargeData, changeMethod: handlePageChanged }"
    >
      <en-table-column width="50"
                      type="index"></en-table-column>
      <en-table-column
        v-for="data in columns"
          ref="enTableColumn"
          class="business-table-column"
          :align="data.align"
          :data="data"
          :key="data.field"
          min-width="200"
          :fixed="data.fixed">
          <template v-slot="{ row, column }">
            <span v-if="column.field === 'payAmount'">{{ row[column.field] | fmoney }} </span>
            <span v-else>{{ row[column.field] }} </span>
          </template>
      </en-table-column>
      <template #paginationLeft>
        <div class="field-statistics">
          <span class="content" v-for="item in rechargeData.commParams.sumFields" :key="item.id">
            {{ item.name }}: <span class="value">{{ item.value | fmoney }}</span>
          </span>
        </div>
      </template>
    </en-table>
  </en-dialog>
</template>

<script>
import { mapState } from "vuex";
import { rechargeService } from "@/api";

export default {
  data() {
    return {
      visible: false,
      loading: false,
      rechargeData: {
        pageNo: 1,
        pageSize: 10,
        records: [],
        commParams: {
          sumFields: []
        }
      },
      columns: [
        {
          field: "payTime",
          name: "支付时间",
          align: "left"
        },
        {
          field: "flowNo",
          name: "交易流水号",
          align: "left"
        },
        {
          field: "payAmount",
          name: "充值金额(元)",
          align: "right"
        }
      ]
    };
  },
  computed: {
    ...mapState(["userInfo"])
  },
  methods: {
    show() {
      this.visible = true;
      this.queryUserOnlineRechargeList();
    },
    handlePageChanged(page) {
      Object.assign(this.rechargeData, page);
      this.queryUserOnlineRechargeList();
    },
    async queryUserOnlineRechargeList() {
      const { pageNo, pageSize } = this.rechargeData;
      const params = {
        pageNo,
        pageSize,
        userId: this.userInfo.userId
      };
      this.loading = true;
      const res = await rechargeService.queryUserOnlineRechargeList(params);
      this.loading = false;
      this.rechargeData = res;
    }
  }
};
</script>

<style lang="scss">
.el-dialog {
  .field-statistics {
    font-size: 12px;
    .value {
      font-weight: bold;
    }
  }
}
</style>
