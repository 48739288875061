<template>
  <div class="recharge-center">
    <div class="recharge-container">
       <div class="title">充值中心</div>
       <div class="content-wrap" v-en-loading="loading">
         <div class="content">
            <div class="header">
              <span>我的余额</span>
              <div>
                <en-button type="text" icon="iconbus-btn26_1" icon-color="#179eda" icon-size="18" @click="viewRechargeRecords">充值记录</en-button>
                <en-button type="text" icon="iconxiaofeijilu" icon-color="#4ed3c2" icon-size="18" @click="viewConsumeRecords">消费记录</en-button>
              </div>
            </div>
            <div class="info">
              <p>账号可用余额(元)</p>
              <h3>{{ balance | fmoney }}</h3>
              <en-button type="primary" style="width: 200px;height: 40px;" @click="handleRecharge">充值</en-button>
            </div>
         </div>
       </div>
       <recharge-records-dialog ref="rechargeRecordsDialog"></recharge-records-dialog>
       <comsume-dialog ref="comsumeDialog"></comsume-dialog>
       <recharge-dialog ref="rechargeDialog"></recharge-dialog>
    </div>
  </div>
</template>

<script>
import { rechargeService } from "@/api";
import rechargeRecordsDialog from "./rechargeRecordsDialog.vue";
import comsumeDialog from "./comsumeDialog.vue";
import rechargeDialog from "./rechargeDialog";

export default {
  name: "recharge",
  components: {
    rechargeRecordsDialog,
    comsumeDialog,
    rechargeDialog
  },
  data() {
    return {
      balance: "0",
      loading: true
    };
  },
  methods: {
    viewRechargeRecords() {
      this.$refs.rechargeRecordsDialog.show();
    },
    viewConsumeRecords() {
      this.$refs.comsumeDialog.show();
    },
    handleRecharge() {
      this.$refs.rechargeDialog.show();
    },
    async queryUserAmount() {
      const res = await rechargeService.queryUserAmount();
      this.loading = false;
      this.balance = res.amount;
    }
  },
  mounted() {
    this.queryUserAmount();
  }
};
</script>

<style lang="scss" scoped>
.recharge-center {
  padding: 10px;
  height: 100%;
  .recharge-container {
    background: #fff;
    border-radius: 4px;
    height: 100%;
    .title {
      border-bottom: 1px solid #ecf1f7;
      padding: 5px 15px;
      line-height: 40px;
      font-size: 14px;
      font-weight: bold;
      height: 50px;
      color: #333
    }
    .content-wrap {
      padding: 20px;
      .header {
        height: 60px;
        line-height: 60px;
        margin: 0 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ecf1f7;
      }
      .content {
        background-color: #f6f7fb;
        border-radius: 10px;
        height: 300px;
        .info {
          text-align: center;
          p {
            font-size: 14px;
            color: #606060;
            opacity: 0.6;
            padding-top: 40px;
          }
          h3 {
            display: block;
            font-size: 42px;
            color: #333;
            padding-top: 1px;
            padding-bottom: 16px;
            margin: 0;
            font-weight: normal;
          }
        }
      }
    }
  }
}
</style>
